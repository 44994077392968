<template>
  <div class="youtube-video">
    <div class="pb-9/16 relative overflow-hidden">
      <div ref="video" :id="`video${videoId}`" class="video absolute left-0 top-0 w-full h-full z-10" :data-video-id="videoId"></div>
      <div class="poster absolute left-0 top-0 w-full h-full z-20" ref="poster" v-on:click="playVideo" v-show="showPoster">
        <img :src="poster" class="block absolute left-0 top-0 w-full h-full object-cover max-w-none">
        <div class="bg-black absolute left-0 bottom-0 w-full h-full opacity-20"></div>
        <div class="rounded-full bg-sensor-200 text-white absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 w-24 h-24 flex flex-row items-center justify-center group-hover:bg-white group-hover:text-sensor-200 transition transition-colors">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="block fill-current ml-2" width="30" height="30">
            <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YoutubeVideo',
  props: {
      videoId: {
        type: String,
        default: null
      }
  },
  data () {
    return {
      initialised: false,
      player: null,
      state: null,
      showPoster: true,
      scriptAdded: false
    }
  },
  methods: {
    initYoutubeApi () {
      if (!this.scriptAdded) {
        const doc = window.document;
        let playerApiScript = doc.createElement('script');
        playerApiScript.type = 'text/javascript';
        playerApiScript.src = 'https://www.youtube.com/iframe_api';
        doc.body.appendChild(playerApiScript);
        this.scriptAdded = true
      }
    },
    playVideo () {
      const self = this
      if (!self.player) {
        self.initVideo()
      } else {
        self.player.seekTo(0)
        self.player.playVideo()
        self.showPoster = false
      }
    },
    initVideo () {
      const self = this
      self.player = new YT.Player(self.containerId, {
        height: '390',
        width: '640',
        videoId: self.videoId,
        playerVars: {
          modestbranding: true,
          showinfo: 0,
          origin: window.location.origin
        },
        events: {
          onReady (event) {
            event.target.seekTo(0)
            event.target.playVideo()
            self.showPoster = false
          },
          onStateChange (event) {
            self.state = event.data
            if (event.data === 0) {
              self.showPoster = true
            }
          }
        }
      })
    }
  },
  computed: {
    containerId () {
      return this.$refs.video.getAttribute('id')
    },
    poster () {
      return `https://img.youtube.com/vi/${this.videoId}/0.jpg`
    }
  },
  mounted() {
    this.initYoutubeApi()
  }
}
</script>

<style lang="scss" scoped>
  .poster {
    cursor: pointer;
  }
</style>
